import type { Session } from '@faststore/sdk'
import axios from 'axios'
import { set } from 'idb-keyval'
import { validateSession } from 'src/sdk/session'
import { setCookie } from 'src/utils/cookie'

import { cartGenerate } from './generateCart'

export interface PostalCodeData {
  postalCode: string
  city: string
  state: string
  country: string
  street: string
  number: string
  neighborhood: string
  complement: string
  reference: string
  geoCoordinates: number[]
}

interface HandleRegionalizeUserProps {
  cep: string
  postalCodeData: PostalCodeData
  partialSession: Session
}

async function setRegionIdCookie(session: Session) {
  // Validate the feasible duration for this cookie. A logic to handle the cookie expiration should be necessary too, in cases the user has an older session.
  const COOKIE_DURATION = 30 // 30 days
  const channel = JSON.parse(session.channel ?? 'null')

  setCookie('fs_region_id', channel?.regionId as string, COOKIE_DURATION)
}

export const regionalizeUser = async ({
  cep,
  postalCodeData,
  partialSession,
}: HandleRegionalizeUserProps) => {
  const cartID = await cartGenerate({ reload: false })

  try {
    partialSession.postalCode = cep
    const newSession = await validateSession({
      ...partialSession,
      postalCode: cep,
      country: 'BRA',
    })

    if (newSession && navigator?.cookieEnabled) {
      // Set cookie for the regionId
      await setRegionIdCookie(newSession)

      await set('fs::session', newSession)
    }
  } catch (error) {
    console.error(error)
  }

  try {
    await axios.post('/api/regionalizeOrderform', {
      postalCodeData,
      orderformcartId: cartID,
    })
  } catch (error) {
    console.error(error)
  }

  try {
    const { state, city } = postalCodeData

    if (navigator?.cookieEnabled) {
      await set('main::store::regionData', {
        city,
        state,
        hasModalOpened: false,
      })
    }
  } catch (error) {
    console.error(error)
  }

  window.location.reload()
}
